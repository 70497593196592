<template>
    <Toast />
    <div class="flex flex-row w-full" style="height: 4px">
        <div class="h-full w-2" style="background-color: #364de7" />
        <div class="h-full w-4" style="background-color: #3ba7e3" />
        <div class="h-full w-6" style="background-color: #91e8a5" />
    </div>
    <div class="flex justify-content-center bg-white" style="font-family: Poppins-Regular, sans-serif">
        <div class="flex flex-column align-items-center min-h-screen h-full w-full px-4" style="max-width: 600px">
            <router-view v-slot="{ Component }" @next-page="nextPage" @prev-page="prevPage">
                <keep-alive>
                    <component ref="component" :is="Component" />
                </keep-alive>
            </router-view>
        </div>
    </div>
</template>
<script setup>
import { onBeforeMount, ref } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import { getClientBase } from '@/services/http';
import { getAutoagendamentoToken } from '@/common/storage';
import StatusAgendamento from '@/enums/StatusAgendamento';

const component = ref('component');
const route = useRoute();
const router = useRouter();
const steps = [
    '/autoagendamento/login',
    '/autoagendamento/confirmar-dados',
    '/autoagendamento/local',
    '/autoagendamento/data',
    '/autoagendamento/confirmado'
];

onBeforeRouteUpdate(({ fullPath }) => {
    validarRota(fullPath);
});

onBeforeMount(() => {
    validarRota(route.fullPath);
});

async function validarRota(rota) {
    const autoagendamentoToken = getAutoagendamentoToken();
    if (autoagendamentoToken) {
        const auxHeaders = { Authorization: `Bearer ${autoagendamentoToken}` };
        const { data } = await getClientBase(auxHeaders).get('/autoagendamento/dados-agendamento');

        const rotaConfirmado = rota === '/autoagendamento/confirmado';
        const confirmado = data?.status !== StatusAgendamento.AUTOAGENDAMENTO;

        if (!rotaConfirmado && confirmado) router.replace('/autoagendamento/confirmado');
        else if (rotaConfirmado && !confirmado) router.replace('/autoagendamento/confirmar-dados');
    }
}
function nextPage() {
    const stepAtual = steps.indexOf(route.fullPath);
    const stepSeguinte = steps[stepAtual + 1];
    if (stepSeguinte) {
        router.push(stepSeguinte);
    }
}
function prevPage() {
    const stepAtual = steps.indexOf(route.fullPath);
    const stepAnterior = steps[stepAtual - 1];
    if (stepAnterior) {
        router.push(stepAnterior);
    }
}
</script>
<style scoped>
:deep(.poppins-regular) {
    font-family: Poppins-Regular, sans-serif;
}
:deep(.poppins-medium) {
    font-family: Poppins-Medium, sans-serif;
}
:deep(.poppins-bold) {
    font-family: Poppins-Bold, sans-serif;
}
:deep(.p-inputswitch-checked .p-inputswitch-slider) {
    background: #3f51b5 !important;
}
:deep(.p-checkbox-checked .p-checkbox-box) {
    background: #3f51b5 !important;
    border-color: #3f51b5 !important;
}
</style>
